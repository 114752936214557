export const InsightName = {
  ActiveLiquorLicense: "ActiveLiquorLicense",
  AcuteSaferViolations: "AcuteSaferViolations",
  AdultEntertainment: "AdultEntertainment",
  AdultMerchandise: "AdultMerchandise",
  AerospaceIndustryComponents: "AerospaceIndustryComponents",
  AggravatedAssaultScore: "AggravatedAssaultScore",
  AirportWork: "AirportWork",
  AllAvailableMeals: "AllAvailableMeals",
  AnnualPayrollRange: "AnnualPayrollRange",
  AnnualRevenue: "AnnualRevenue",
  AnsulSystem: "AnsulSystem",
  Arcade: "Arcade",
  AsbestosAbatement: "AsbestosAbatement",
  Bakery: "Bakery",
  Bankruptcy: "Bankruptcy",
  BanquetHall: "BanquetHall",
  Bar: "Bar",
  BarTavern: "BarTavern",
  BeauticiansEmployed: "BeauticiansEmployed",
  BeerGarden: "BeerGarden",
  Blasting: "Blasting",
  BrewPub: "BrewPub",
  BridgeWork: "BridgeWork",
  BuildingFootprintSqft: "BuildingFootprintSqft",
  BuildingStoriesCount: "BuildingStoriesCount",
  BuildzoomPage: "BuildzoomPage",
  BurglarAlarm: "BurglarAlarm",
  BurglaryScore: "BurglaryScore",
  BusinessDescriptionGPT: "BusinessDescriptionGPT",
  BusinessKeywords: "BusinessKeywords",
  BusinessOwnerName: "BusinessOwnerName",
  BusinessSquareFeet: "BusinessSquareFeet",
  BYOB: "BYOB",
  Cafeteria: "Cafeteria",
  Cannabis: "Cannabis",
  CargoCarrierClassifications: "CargoCarrierClassifications",
  Carpenter: "Carpenter",
  Caterer: "Caterer",
  Catering: "Catering",
  CocktailBar: "CocktailBar",
  CommercialVehiclesAmount: "CommercialVehiclesAmount",
  CommercialVehiclesTypes: "CommercialVehiclesTypes",
  CommercialWork: "CommercialWork",
  CommercialWorkLandscape: "CommercialWorkLandscape",
  ConsignmentSales: "ConsignmentSales",
  ConstructionIndustryProducts: "ConstructionIndustryProducts",
  ConstructionOperations: "ConstructionOperations",
  ConstructionType: "ConstructionType",
  ContractorLicenses: "ContractorLicenses",
  Cranes: "Cranes",
  DamageRestoration: "DamageRestoration",
  Dancing: "Dancing",
  DeepFrying: "DeepFrying",
  Deliveries: "Deliveries",
  Delivery: "Delivery",
  DeliveryByTheBusiness: "DeliveryByTheBusiness",
  DeliveryPercentRange: "DeliveryPercentRange",
  DemolitionService: "DemolitionService",
  DesignPrototyping: "DesignPrototyping",
  Diner: "Diner",
  DistanceToClosestBuilding: "DistanceToClosestBuilding",
  DistanceToNearestBeachMiles: "DistanceToNearestBeachMiles",
  DistanceToNearestCoastMiles: "DistanceToNearestCoastMiles",
  DJ: "DJ",
  DoingBusinessAs: "DoingBusinessAs",
  DoormanBouncer: "DoormanBouncer",
  DOTNumber: "DOTNumber",
  DoughnutShop: "DoughnutShop",
  Drilling: "Drilling",
  DriverStaff: "DriverStaff",
  DryCleaning: "DryCleaning",
  EffectiveYearBuilt: "EffectiveYearBuilt",
  ElectricWork: "ElectricWork",
  EmailAddress: "EmailAddress",
  EntityType: "EntityType",
  EquipmentRental: "EquipmentRental",
  EstablishmentYear: "EstablishmentYear",
  Excavation: "Excavation",
  ExternalFramingService: "ExternalFramingService",
  FacebookPage: "FacebookPage",
  Fencing: "Fencing",
  FineDining: "FineDining",
  FireAlarm: "FireAlarm",
  FoodPoisoningIndex: "FoodPoisoningIndex",
  FoodSalesAbove20Percent: "FoodSalesAbove20Percent",
  FoundPhrases: "FoundPhrases",
  FoursquarePage: "FoursquarePage",
  GeneralContractor: "GeneralContractor",
  GoodForKids: "GoodForKids",
  GooglePlacesPage: "GooglePlacesPage",
  GunSales: "GunSales",
  GutterServices: "GutterServices",
  HappyHour: "HappyHour",
  Hardscaping: "Hardscaping",
  HasOpenOSHAViolations: "HasOpenOSHAViolations",
  HasOSHAInspections: "HasOSHAInspections",
  HasOSHAInspections3Years: "HasOSHAInspections3Years",
  HasOSHAViolations: "HasOSHAViolations",
  HasParkingLot: "HasParkingLot",
  HasPlayground: "HasPlayground",
  HazardousMaterials: "HazardousMaterials",
  HealthcareStaff: "HealthcareStaff",
  HeatAndFireIndex: "HeatAndFireIndex",
  HighVoltageWork: "HighVoltageWork",
  Hookah: "Hookah",
  HVAC: "HVAC",
  IndustrialWork: "IndustrialWork",
  Installation: "Installation",
  JuiceShop: "JuiceShop",
  JukeBox: "JukeBox",
  Karaoke: "Karaoke",
  Keywords: "Keywords",
  LarcenyTheftScore: "LarcenyTheftScore",
  LatestOpenHour: "LatestOpenHour",
  LawnCare: "LawnCare",
  LawnSprinkler: "LawnSprinkler",
  LeadAbatement: "LeadAbatement",
  LeasedCommercialVehicles: "LeasedCommercialVehicles",
  LegalName: "LegalName",
  Liquor: "Liquor",
  LiquorReceiptsPercentRange: "LiquorReceiptsPercentRange",
  LiquorToGo: "LiquorToGo",
  ListOfProducts: "ListOfProducts",
  LiveEntertainment: "LiveEntertainment",
  LiveMusic: "LiveMusic",
  MainAddress: "MainAddress",
  MainWebsite: "MainWebsite",
  MajorWorkplaceInjuries: "MajorWorkplaceInjuries",
  ManufacturingOnPremises: "ManufacturingOnPremises",
  MedicalCosmeticIndustryProducts: "MedicalCosmeticIndustryProducts",
  MedicalEquipmentSales: "MedicalEquipmentSales",
  MilitaryIndustry: "MilitaryIndustry",
  MobileBusiness: "MobileBusiness",
  MoldAbatement: "MoldAbatement",
  MultipleEntities: "MultipleEntities",
  NAICSClassificationGPT: "NAICSClassificationGPT",
  NewConstruction: "NewConstruction",
  Nightclub: "Nightclub",
  NumberOfOSHAInspections3Years: "NumberOfOSHAInspections3Years",
  NYCDOBPropertyProfile: "NYCDOBPropertyProfile",
  NYCOpenDataComplaintsClose: "NYCOpenDataComplaintsClose",
  NYCOpenDataComplaintsOpen: "NYCOpenDataComplaintsOpen",
  NYCOpenDataDOBClose: "NYCOpenDataDOBClose",
  NYCOpenDataDOBOpen: "NYCOpenDataDOBOpen",
  NYCOpenDataECBClose: "NYCOpenDataECBClose",
  NYCOpenDataECBOpen: "NYCOpenDataECBOpen",
  OFCCPComplaintInvestigations: "OFCCPComplaintInvestigations",
  OffPremiseCatering: "OffPremiseCatering",
  OffPremiseCateringPercentRange: "OffPremiseCateringPercentRange",
  OffPremiseInstallation: "OffPremiseInstallation",
  OffPremiseRepairs: "OffPremiseRepairs",
  Open247Hours: "Open247Hours",
  OutdoorClasses: "OutdoorClasses",
  OutdoorSeating: "OutdoorSeating",
  OverseasStaff: "OverseasStaff",
  Painting: "Painting",
  PayrollCompany: "PayrollCompany",
  PesticideOrFertilizerApplication: "PesticideOrFertilizerApplication",
  Phone: "Phone",
  PizzaPlace: "PizzaPlace",
  PlumbingFixtures: "PlumbingFixtures",
  PlumbingServices: "PlumbingServices",
  PoolTable: "PoolTable",
  PossibleTruckTransportation: "PossibleTruckTransportation",
  PowerUnitsAmount: "PowerUnitsAmount",
  PropertyCrimeScore: "PropertyCrimeScore",
  RadonAbatement: "RadonAbatement",
  RemodelingWork: "RemodelingWork",
  RentalPartyFurniture: "RentalPartyFurniture",
  Repair: "Repair",
  Reservations: "Reservations",
  ResidentialTenants: "ResidentialTenants",
  ResidentialWork: "ResidentialWork",
  RestaurantPriceIndex: "RestaurantPriceIndex",
  RestaurantTenants: "RestaurantTenants",
  RetailBakingPrepHotFood: "RetailBakingPrepHotFood",
  RoadOrStreetWork: "RoadOrStreetWork",
  Roofing: "Roofing",
  RoofingService: "RoofingService",
  SaferCarriersOperations: "SaferCarriersOperations",
  SaferUrl: "SaferUrl",
  SeasonalEmployees: "SeasonalEmployees",
  SeasonalOperation: "SeasonalOperation",
  SeatingCapacity: "SeatingCapacity",
  SlipFallRBTIndex: "SlipFallRBTIndex",
  SnowPlowing: "SnowPlowing",
  SnowRemoval: "SnowRemoval",
  SocialRating: "SocialRating",
  Solar: "Solar",
  SponsorsEvents: "SponsorsEvents",
  SportsBar: "SportsBar",
  SprayPainting: "SprayPainting",
  StoreType: "StoreType",
  StripClub: "StripClub",
  Subcontractor: "Subcontractor",
  TableService: "TableService",
  TableSideCooking: "TableSideCooking",
  TechStaffing: "TechStaffing",
  ThirdPartyDelivery: "ThirdPartyDelivery",
  Tobacco: "Tobacco",
  TotalEmployeeCount: "TotalEmployeeCount",
  TotalSaferInspections: "TotalSaferInspections",
  TotalSaferViolations: "TotalSaferViolations",
  TrafficSignalWork: "TrafficSignalWork",
  TransportationMotorIndustry: "TransportationMotorIndustry",
  TreeRemoval: "TreeRemoval",
  TreeTrimming: "TreeTrimming",
  TwentyFourHoursOpening: "24HoursOpening",
  TypeOfRestaurantOperations: "TypeOfRestaurantOperations",
  UndergroundWork: "UndergroundWork",
  UsedEquipment: "UsedEquipment",
  VehicleExposure: "VehicleExposure",
  VehicleRelatedInjuries: "VehicleRelatedInjuries",
  ViolentCrimeScore: "ViolentCrimeScore",
  VolunteerDonatedLabor: "VolunteerDonatedLabor",
  WageHourComplianceInspections: "WageHourComplianceInspections",
  Warranties: "Warranties",
  WatercraftIndustry: "WatercraftIndustry",
  WineBar: "WineBar",
  WorkInAltitude: "WorkInAltitude",
  WorkplaceFatalities: "WorkplaceFatalities",
  YearBuilt: "YearBuilt",
  YellowPagesPage: "YellowPagesPage",
  YelpPage: "YelpPage",

  PossibleDBAs: "PossibleDBAs",
  IncorporationYear: "IncorporationYear",
  RegisteringContactName: "RegisteringContactName",
  SICCodes: "SICCodes",
  SwimmingPoolOrShore: "SwimmingPoolOrShore",
  IsResidentialAddress: "IsResidentialAddress",
  RobberyScore: "RobberyScore",
  PriceLevel: "PriceLevel",
  Gambling: "Gambling",
  RestaurantOperationTypes: "RestaurantOperationTypes",
  Buffet: "Buffet",
  CoffeeShop: "CoffeeShop",
  IceCreamShop: "IceCreamShop",
  GentlemansClub: "GentlemansClub",
  SandwichShop: "SandwichShop",
  CuisineTypes: "CuisineTypes",
  HardLiquor: "HardLiquor",
  NameOfLiquorLicensee: "NameOfLiquorLicensee",
  OffPremiseOperation: "OffPremiseOperation",
  TV: "TV",
  DriveThru: "DriveThru",
  LocationMultiStory: "LocationMultiStory",
  LatestSafetyInspection: "LatestSafetyInspection",
  LatestHealthInspection: "LatestHealthInspection",
  DocksOrBridgeOverWater: "DocksOrBridgeOverWater",
  EnclosedMall: "EnclosedMall",
  ShippingCenter: "ShippingCenter",
  Security: "Security",
  CurrentOwnerName: "CurrentOwnerName",
  CommercialTenants: "CommercialTenants",
  PropertyValue: "PropertyValue",
  HasShore: "HasShore",
  HasSwimmingPool: "HasSwimmingPool",
  LotSizeSqft: "LotSizeSqft",
  LandPurpose: "LandPurpose",
  ExteriorWallType: "ExteriorWallType",
  HeatSource: "HeatSource",
  RoofType: "RoofType",
  RoofShape: "RoofShape",
  CoastalStormsRiskScore: "CoastalStormsRiskScore",
  FireRiskScore: "FireRiskScore",
  FloodRiskScore: "FloodRiskScore",
  FreezingRiskScore: "FreezingRiskScore",
  HurricaneRiskScore: "HurricaneRiskScore",
  OtherDisasterRiskScore: "OtherDisasterRiskScore",
  SnowRiskScore: "SnowRiskScore",
  SevereStormRiskScore: "SevereStormRiskScore",
  SevereIceStormRiskScore: "SevereIceStormRiskScore",
  PropertyForSale: "PropertyForSale",
  PropertyOwner: "PropertyOwner",
  Babysitting: "Babysitting",
  FeeChargedParking: "FeeChargedParking",
  Watercraft: "Watercraft",
  DayCareProvider: "DayCareProvider",
  FraternalSociety: "FraternalSociety",
  HasTaxExemptStatus: "HasTaxExemptStatus",
  IsMuseum: "IsMuseum",
  IsReligiousOrganization: "IsReligiousOrganization",
  SchoolOrUniversity: "SchoolOrUniversity",
  SocialOrCharitableOrganization: "SocialOrCharitableOrganization",
  NetIncome: "NetIncome",
  TotalAssets: "TotalAssets",

  EarliestOpenHour: "EarliestOpenHour", // Configuru
  PrimaryCaterer: "PrimaryCaterer", // Configuru
  BusinessMultiFloors: "BusinessMultiFloors", // Configuru
  BusinessSQFTRange: "BusinessSQFTRange", // Configuru
  Above200EmployeesSingleLocation: "Above200EmployeesSingleLocation", // Configuru
  AggregatedCrimeScore: "AggregatedCrimeScore", // Configuru
} as const

export type InsightName = (typeof InsightName)[keyof typeof InsightName]
export default InsightName
