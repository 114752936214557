export enum BusinessClassification {
  CONSTRUCTION = "construction",
  MANUFACTURING = "manufacturing",
  RBNT = "rbnt",
  RBNT_JAPAN = "rbnt_japan",
  RETAIL = "retail",
  STAFFING = "staffing",
  AUTOSERVICES = "autoservices",
  HEALTHCARE = "healthcare",
  LANDSCAPING = "landscaping",
}
